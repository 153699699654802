import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { map } from 'rxjs';
import { Feature } from '@app/shared/types';
import { UserFacade } from '@app/store/user';

export const featureGuard =
    (feature: Feature): CanActivateFn =>
    () => {
        const userFacade = inject(UserFacade);
        const router = inject(Router);

        return userFacade.features$.pipe(map((features) => features.includes(feature) || router.createUrlTree(['/dashboard'])));
    };
