import { inject } from '@angular/core';
import { Router, CanActivateFn } from '@angular/router';
import { UserFacade } from '@app/store/user';
import { MessageService } from '@corelabs/angular-messages';
import { TranslateService } from '@ngx-translate/core';
import { map, take } from 'rxjs';

export function cartGuard(): CanActivateFn {
    return () => {
        const router = inject(Router);
        const userFacade = inject(UserFacade);
        const messageService = inject(MessageService);
        const translateService = inject(TranslateService);

        return userFacade.blocked$.pipe(
            take(1),
            map((isBlocked) => {
                if (isBlocked === 'Ship' || isBlocked === 'Invoice') {
                    messageService.add(translateService.instant('user-blocked'), 'warning');

                    return router.getCurrentNavigation()?.previousNavigation?.finalUrl ?? router.createUrlTree(['/dashboard']);
                }

                return true;
            }),
        );
    };
}
